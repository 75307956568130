<template>
	<div class="tab-view">
		<div class="menus">
			<router-link to="/user/booking/order" class="menu current">我的預約</router-link>
            <router-link to="/user/booking/record" class="menu">就診記錄</router-link>
		</div>
		<div class="tabview-content">
            <div class="booking-list">
                <div :class="{'booking-item': true, ['level'+order.level]: true }" v-for="(order, index) in orderList" :key="order.id">
                    <div class="booking-item-head">
                        <span :class="{'doctor-avatar': true, [order.gender == 1 ? 'man' : 'woman']: true}"></span>
                        <span class="booking-time">{{order.ScheduleDate}} {{order.StartTime.substring(0, 5)}}-{{order.EndTime.substring(0, 5)}}</span>
                        <span class="doctor-name">{{order.DoctorName}}（{{order.ClinicName}}）</span>
                        <span class="booking-status">{{order.statusText}}</span>
                        <span :class="{'btn-more': true, 'extend': order.extend}" @click="onItemClick(index)"></span>
                        <a-popconfirm title="確認取消預約？" ok-text="確定" cancel-text="取消" @confirm="cancelBooking(order)">
                            <a href="javascript:;" :class="{'btn-cancel': true, 'hide': !order.enableCancel}">取消預約</a>
                        </a-popconfirm>
                    </div>
                    <div :class="{'booking-item-all': true, 'show': order.extend}">
                        <div class="doctor-name">{{order.DoctorName}}（{{order.ClinicName}}）</div>
                        <div class="doctor-tel">{{order.ContactTel}}</div>
                        <div class="doctor-address">{{order.Address}}</div>
                        <div :class="{'booing-intro': true, 'hide': !order.Remark}" v-text="order.Remark"></div>
                    </div>
                </div>
            </div>
            <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
            <loading v-if="loading"/>
		</div>
	</div>
</template>

<script>
    import Loading from '@/layouts/Loading.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
    import booking_order from '@/views/mixins/booking_order'
	export default {
        mixins: [
            booking_order
        ],
        components: {
            Loading,
            SplitPage
        },
		mounted: function(){
            this.pathName = this.$route.path
            this.onPageChanged(1)
		},
		data() {
            return {
                visible: false,
                total: 0,
                loading: true,
                pageNo: 1,
                pageSize: 10,
                orderList: [],
                pages: [{
                    index: 1,
                    key: '1',
                    disable: false 
                }]
            }
        },
        methods: {
            onItemClick: function(index) {
                this.orderList[index].extend = !this.orderList[index].extend
                this.orderList.filter((o, i)=>i!=index).forEach(o=>o.extend = false)
            },
            cancelBooking: function(order) {
                if(order && order.enableCancel) {
                    this.loading = true
                    this.$store.dispatch('cancelBooking', {
                        scheduleID: order.ScheduleID
                    }).then(d=>{
                        this._toast.success(this, '已經成功取消預約')
                        this.pageNo = 1;
                        var clientId = this.$store.getters.clientId
                        this.loadData(clientId, this.pageNo, this.pageSize, (data, error)=>{
                            this.orderList = (data && data.list) || []
                            this.total = (data && data.total) || 0
                            this.loading = false
                            this.visible = this.total > 0
                        })
                    }).catch(error=>{
                        this.loading = false
                        this._toast.warning(this, error)
                    })
                }
            },
            onPageChanged: function(index) {
                this.pageNo = index
                this.loading = true
                var clientId = this.$store.getters.clientId
                this.loadData(clientId, this.pageNo, this.pageSize, (data, error)=>{
                    this.orderList = (data && data.list) || []
                    this.total = (data && data.total) || 0
                    this.loading = false
                    this.visible = this.total > 0
                })
            }
        }
	};
</script>

<style lang="scss" scoped>
    .tab-view {
		width: 1000px;
		display: flex;
		flex-direction: row;
		margin: 0px auto 40px auto;
		position: relative;
	}

	.tab-view > .menus {
		display: flex;
		flex-direction: column;
		width: 164px;
		margin-right: 10px;
		margin-top: -1px;
	}

	.tab-view > .menus > .menu {
		display: block;
		width: 164px;
		height: 50px;
		margin-top: 1px;
		background-color: #fff;
		line-height: 50px;
		text-align: center;
		font-weight: 400;
		color: #969696;
		font-size: 16px;
	}

	.tab-view > .menus > .menu.current {
		color: #36C4D0;
		font-weight: bold;
		position: relative;
	}

	.tab-view > .menus > .menu.current::after {
		content: '';
		position: absolute;
		right: 10px;
		top: 20px;
		width: 7px;
		height: 10px;
		background-image: url(../../../../assets/imgs/pc/user/img_more@2x.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.tab-view > .tabview-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		background-color: #fff;
		min-height: 466px;
        box-sizing: border-box;
        position: relative;
        padding-bottom: 60px;
    }

    .booking-list {
        display: flex;
        flex-direction: column;
    }

    .booking-list > .booking-item {
        display: flex;
        flex-direction: column;
        margin: 20px 20px 0px 20px;
    }

    .booking-list > .booking-item > .booking-item-head {
        height: 80px;
        position: relative;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 2px 2px 2px #ededed, -1px -1px 2px #ededed;
    }

    .booking-list > .booking-item.level2 > .booking-item-head {
        background-color: #F1F1F1;
    }

    .booking-list > .booking-item > .booking-item-head > .doctor-avatar {
        width: 42px;
        height: 43px;
        background-image: url(../../../../assets/imgs/pc/user/img_nanys@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 10px;
        position: absolute;
        left: 10px;
        top: 21px;    
    }

    .booking-list > .booking-item > .booking-item-head > .booking-time {
        color: #231F20;
        height: 18px;
        line-height: 18px;
        font-weight: bold;
        font-size: 18px;
        position: absolute;
        left: 71px;
        top: 20px;
    }

    .booking-list > .booking-item > .booking-item-head > .doctor-name {
        position: absolute;
        left: 72px;
        top: 49px;
        color: #636363;
        font-size: 16px;
        line-height: 16px;
        height: 16px;
    }

    .booking-list > .booking-item > .booking-item-head > .booking-status {
        height: 24px;
        background-color: #FFE3D5;
        line-height: 24px;
        padding: 0px 9px;
        font-weight: bold;
        color: #FF8000;
        font-size: 16px;
        border-radius: 12px;
        position: absolute;
        right: 100px;
        top: 17px;
    }

    .booking-list > .booking-item.level0 > .booking-item-head > .booking-status {
        color: #FF8000;
        background-color:#FFE3D5;
    }

    .booking-list > .booking-item.level1 > .booking-item-head > .booking-status {
        color: #2A8737;
        background-color:#C5E6C7;
    }

    .booking-list > .booking-item.level2 > .booking-item-head > .booking-status {
        color: #231F20;
        background-color: #E8E8E8;
    }

    .booking-list > .booking-item > .booking-item-head > .btn-more {
        position: absolute;
        right: 23px;
        top: 17px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }

    .booking-list > .booking-item .btn-cancel {
        position: absolute;
        right: 107px;
        top: 52px;
        font-size: 12px;
        line-height: 15px;
        color: #333;
        cursor: pointer;
        text-decoration: underline;
    }

    .booking-list > .booking-item .btn-cancel.hide {
        display: none;
    }

    .booking-list > .booking-item > .booking-item-head > .btn-more::before {
        content: '';
        position: absolute;
        left: 6px;
        top: 6px;
        width: 12px;
        height: 12px;
        background-image: url(../../../../assets/imgs/pc/user/img_xlal@2x.png);
        background-size: 12px 12px;
        background-repeat: no-repeat;
    }

    .booking-list > .booking-item > .booking-item-head > .btn-more.extend::before {
        background-image: url(../../../../assets/imgs/pc/user/img_xialh@2x.png);
    }

    .booking-list > .booking-item > .booking-item-all {
        border-radius: 12px;
        box-shadow: 2px 2px 2px #ededed, -2px 0px 2px #ededed;
        padding-top: 18px;
        margin-top: 2px;
        background-color: #fff;
        display: none;
    }

    .booking-list > .booking-item > .booking-item-all.show {
        display: block;
    }

    .booking-list > .booking-item > .booking-item-all > .doctor-tel,
    .booking-list > .booking-item > .booking-item-all > .doctor-address,
    .booking-list > .booking-item > .booking-item-all > .doctor-name {
        position: relative;
        padding-left: 35px;
        color: #231F20;
        font-size: 16px;
        height: 16px;
        line-height: 16px;
        font-weight: 400;
        margin-left: 20px;
        margin-bottom: 15px;
    }

    .booking-list > .booking-item > .booking-item-all > .doctor-name::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        background-image: url(../../../../assets/imgs/pc/user/icon_yishi@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .booking-list > .booking-item > .booking-item-all > .doctor-tel::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 14px;
        height: 14px;
        background-image: url(../../../../assets/imgs/pc/user/img_iphone@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .booking-list > .booking-item > .booking-item-all > .doctor-address::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 11px;
        height: 14px;
        background-image: url(../../../../assets/imgs/pc/user/img_dib@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .booking-list > .booking-item > .booking-item-all > .booing-intro {
        margin: 5px 20px 10px 20px;
        background-color: #EFF7F8;
        border-radius: 8px;
        padding: 8px 10px 24px 13px;
        color: #969696;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }

    .booking-list > .booking-item > .booking-item-all > .booing-intro.hide {
        display: none;
    }

	.split-pages-container {
		position: absolute;
		bottom: 20px;
		right: 20px;
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		justify-content: flex-end;
	}

	.split-pages-container > .split-pages {
		display: flex;
		flex-direction: row;
	}

	.split-pages-container > .split-pages > .split-page {
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		color: #969696;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		font-weight: 400;
		margin-right: 20px;
		background-color: #DDDDDD;
		cursor: pointer;
	}

	.split-pages-container > .split-pages > .split-page.more {
		line-height: 16px;
	}

	.split-pages-container > .split-pages > .split-page:hover,
	.split-pages-container > .split-pages > .split-page.current {
		color: #fff;
		background-color: #36C4D0;
		font-weight: bold;
	}

	.split-pages-container > .btn-page-prev,
	.split-pages-container > .btn-page-next {
		height: 20px;
		width: 56px;
		text-align: center;
		line-height: 20px;
		font-weight: 300;
		color: #636363;
		background-color: #DDDDDD;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		cursor: pointer;
	}

	.split-pages-container > .btn-page-next {
		margin-left: 24px;
		color: #231F20;
	}
</style>
