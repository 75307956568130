export default {
    methods: {
        async loadData(clientId, pageNo, pageSize, callback) {
            var rs = await this.$store.dispatch('getBookingList', {
               clientId: clientId,
               pageNo: pageNo,
               pageSize: pageSize,
            })
            if(rs && rs.total != null) {
                var list = rs.list || []
                if(list.length > 0) {
                    list.forEach((item)=> {
                        item.extend = false
                        var auditstatus = item.Status
                        item.enableCancel = false
                        if(auditstatus == 'O') {
                            item.statusText = '已過期' 
                            item.levelText = 'level0'
                            item.level = 2
                        } else if(auditstatus == 'C') {
                            item.statusText = '已取消'
                            item.levelText = 'level0'
                            item.level = 2
                        } else if(auditstatus == 'H') {
                            item.statusText = '待確認'
                            item.levelText = 'level2'
                            item.enableCancel = true
                            item.level = 0
                        } else if(auditstatus == 'U') {
                            item.statusText = '已就診'
                            item.levelText = 'level1'
                            item.level = 1
                        } else {
                            item.statusText = '已確認'
                            item.levelText = 'level1'
                            item.level = 1
                        }
                    })
                }
                callback({
                    list: list,
                    total: rs.total,
                }, null)
            } else {
                callback(null, rs)
            }
        }
    }
}